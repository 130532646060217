@use '../../../utils/sass/_colors.sass' as *

.radioGroup
  font-size: 1rem
  height: 3.75em

.groupTitle
  margin-bottom: 1em
  font-weight: bold
  font-size: 1em
  font-family: 'HelveticaNeue-Medium', sans-serif

.options
  display: flex

.option
  margin-right: 1em

.option > input
  margin-right: 1em

.inputErrorFeedback
  color: $redError
