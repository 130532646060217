.wrapper
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    display: flex
    justify-content: center
    align-items: center

.window
    position: relative
    background: #fff
    border-radius: 5px
    padding: 15px
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3)
    z-index: 3 
    min-width: 550px
    min-height: 200px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.background
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(0, 0, 0, 0.3)

.header
    margin: 0 
    text-align: center
    display: flex
    flex-direction: column
    align-items: center

.tittle
    max-width: 70%
    font-size: 28px
    font-weight: 700
    margin-top: 15px
    pading: 0 
    line-height: 36px


.loader
    display: flex
    justify-content: center
    align-items: center


.spinner 
    position: relative
    width: 80px
    height: 80px
    animation: rotateDiv 1.9s linear infinite
    circle
        width: 100%
        height: 100%
        fill: none
        stroke-width: 30    
        stroke: #4076d2
        stroke-linecap: round
        stroke-dasharray: 440
        stroke-dashoffset: 440
        animation: animate 2s linear infinite



@keyframes animate
    0%, 100%
        stroke-dashoffset: 440
    
    50%
        stroke-dashoffset: 0
    
    50.1%
        stroke-dashoffset: 880

@keyframes rotateDiv
    0%
        transform: rotate(0deg)
    100%
        transform: rotate(360deg) 