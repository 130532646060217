@use '../../../utils/sass/_colors.sass' as *

p
  font-family: "HelveticaNeue", sans-serif

.whiteSpace
  height: 300px

.important > div > h4
  font-size: 1.5em !important

.contactForm
  font-size: 1rem
  width: 100%
  padding: 1.5em
  background-color: white
  box-shadow: 0px 3px 6px rgba(18, 18, 18, 0.16)
  border-radius: 8px
  overflow: hidden

.title
  font-family: "CircularStd-Bold", sans-serif
  font-size: 2em

.subtitle
  font-size: 1.5rem
  font-weight: bold
  font-family: "CircularStd-Bold", sans-serif
  margin: 0px

.extraInfo
  font-size: 1em

.secondaryTitle
  font-family: "HelveticaNeue-Bold", sans-serif
  font-size: 1em

.inputsSection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em

.recomendations
  font-size: 1em
  font-family: "HelveticaNeue", sans-serif

  > li
    margin-bottom: 1em

  @media (max-width: 600px)
    padding: 1em

@media screen and (max-width: 600px)
.inputsSection
  grid-template-columns: 1fr

.radiosSection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em

.phoneRadios
  grid-row: 1/2

.emailRadios
  grid-row: 1/2

.heroControls
  height: 6.25rem
  display: flex
  align-items: center
  justify-content: space-between

.btnBack
  display: none
  color: $main-blue
  border-style: none
  font-weight: bold
  letter-spacing: .25px
  background-color: transparent
  cursor: pointer

@media(max-width: 600px)
  .btnBack
    display: none

.btnNext
  width: 312px
  height: 48px
  background: rgb(224, 224, 224)
  border-radius: 24px
  border-style: solid
  font-weight: bold
  letter-spacing: .25px
  cursor: pointer

  @media(max-width: 600px)
    margin: auto

.active
  background-color: $blueInputs
  color: white
  border: none

// buttons
.btnFiles
  margin: 0.5em
  width: 271px
  height: 48px
  font-family: "HelveticaNeue", sans-serif
  cursor: pointer

  @media(max-width: 600px)
    width: 100%
