.container
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    position: relative

.btnRegresarContainer
    margin-top: 1rem
    position: absolute
    top: 0
    left: 0
    @media(max-width: 600px)
        margin-top: 2rem
        position: absolute
        top: 0
        left: 0

.btnRegresar
    background: none
    border: none
    padding: 0
    font-family: HelveticaNeue-Bold
    font-size: 16px
    line-height: 19.36px
    cursor: pointer

.btnRegresar img
    width: 24px
    height: 24px

.imageContainer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.imageLogo 
    @media(max-width: 600px)
        width: 145px
        height: 68.91px
        left: 5rem
    
.changePasswordForm
    width: 593px
    padding-top: 1rem
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    @media(max-width: 600px)
        width: 312px
        padding-top: 0rem

.title
    color: #1B1A16
    font-family: CircularStd-Bold
    font-size: 28px
    line-height: 36px
    margin: 2rem 0 2rem  0
    @media(max-width: 600px)
        color: #1B1A16
        font-family: CircularStd-Bold
        font-weight: 700
        font-size: 20px
        line-height: 28px
        margin: 2rem 0 2rem  0

.passwordCheckListValidation
    margin: 28px 0 28px 0 
    padding: 0 0 0 12px
    @media(max-width: 600px)
        margin: 16px 0 16px 0 
        padding: 0 15% 0 15%


.btnContainer
    margin: 32px 0 100px 0

.childrenModal
    margin: 0
    display: flex
    flex-direction: column
    width: 100%
    text-align: left
    p
        margin: 0
        padding-left: 2%
        font-family: "HelveticaNeue-Medium"
        font-size: 16px
        line-height: 24px
        color: #000000

        

.modalBtn
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 40px 0 20px 0
    padding: 0 40%
    @media (max-width: 768px)
        padding: 0 20%
    @media (max-width: 576px)
        padding: 0 10%