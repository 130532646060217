.container
    background-color: #FFFFFF
    padding: 16px
    border-radius: 16px
    gap: 24px
    box-shadow: 0px 6px 24px 0px rgba(25, 75, 123, 0.04)
    width: 100%
    min-width: 300px

.cardRightImage
    display: grid
    grid-template-columns: 100px 1fr
    grid-gap: 24px
    grid-auto-rows: minmax(100px, auto)
    justify-items: center
    align-items: center
    .body
        justify-self: normal
    @media(max-width: 600px)
        grid-template-columns: 50px 1fr
        grid-gap: 0.9rem

.cardTopImage
    display: grid
    grid-template-columns: 1fr
    grid-auto-rows: minmax(100px, auto)
    justify-items: center
    align-items: center
    
.title
    font-family: 'CircularStd-Bold'
    font-size: 20px
    font-weight: 700
    line-height: 28px
    letter-spacing: 0em
    text-align: left

.description
    font-family: 'HelveticaNeue'
    font-size: 16px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0px
    text-align: left
