$breakpoints: (sm: 576px, md: 768px,lg: 1024px)
  

@mixin media-breakpoint-down($breakpoint) 
  @media (max-width: (map-get($breakpoints, $breakpoint) - 1px))
    @content
    
    
.container
    background-size: 100% auto
    background-position: center center
    background-repeat: no-repeat
    width: 100%
    height: 100%
    padding-top: 100%
    border-radius: 50%
    @include media-breakpoint-down(md)
        background-size: 100% auto
        width: 80%
        height: 80%


.placeHolder
    background-image: url("../../images/DocsIcons/placeholder.svg")

.constancia
    background-image: url("../../images/DocsIcons/icon-constSituacionFiscal.svg")
    
.id
    background-image: url("../../images/DocsIcons/icon-id.svg")

.pasaporte
    background-image: url("../../images/DocsIcons/icon-pasaporte.svg")

.estadoCuenta
    background-image: url("../../images/DocsIcons/icon-estadoCuenta.svg")

.logoIcon
    background-image: url("../../images/DocsIcons/icon-logo.svg")

.validandoDocs
    background-image: url("../../images/DocsIcons/icon-validandoDocs.svg")

.docsError
    background-image: url("../../images/DocsIcons/icon-validandoDocsError.svg")

.docsOk
    background-image: url("../../images/DocsIcons/icon-validandoDocsListo.svg")

.errorEdad
    background-image: url("../../images/DocsIcons/icon-errorEdad.svg")

.errorSolicitud
    background-image: url("../../images/DocsIcons/icon-errorSolicitud.svg")

.errorId
    background-image: url("../../images/DocsIcons/icon-errorId.svg")

