.btnInactive
    background-color: #BDBDBD
    width: 100%
    border: none
    border-radius: 25px
    color: #1B1A16
    cursor: pointer
    font-size: 16px
    font-family: "HelveticaNeue-Medium", sans-serif
    font-weight: 700
    padding: 0.5rem 1rem
    transition: all 0.3s ease-in-out
    &:hover
        color: #fff

.btnActive
    background-color: #006FB9
    width: 100%
    border: none
    border-radius: 25px
    color: #fff
    cursor: pointer
    font-size: 16px
    font-family: "HelveticaNeue-Medium", sans-serif
    font-weight: 700
    padding: 0.5rem 1rem
    transition: all 0.3s ease-in-out
    &:hover
        color: #fff