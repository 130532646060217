
$blueInputs: #006fb9
$grayBg: #f5f5f5
$footer-bg-color: #00253d
$footer-legal-color: #bdbdbd
$buttonGray: #bdbdbd
$lightGray: #bdbdbd
$main-blue: #006fb9

// input colors
$inputTitleBlack: #1B1A16
$inputBlue: #006fb9
$inputBgGray: #f9f9f9
$inputGray: #757575
$inputStrongGray: #636363
$redError: #f44336
