@use '../../../../utils/sass/_colors.sass' as *
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

/* Firefox */
input[type=number]
  -moz-appearance: textfield

.titleAndDescription
  margin-top: 1.5em
  margin-bottom: 1.5em

.secondaryTitle
  font-family: 'CircularStd-Bold'
  margin-bottom: .5em
  font-size: 1.5em

.sectionDescription
  font-size: 1em
  font-family: 'HelveticaNeue-medium'

.title
  font-family: "CircularStd-Bold"
  font-size: 2rem !important
  
  @media (max-width: 600px)
    font-size: 24px !important
    line-height: 30px

.subtitle
  font-size: .8125rem
  font-family: "HelveticaNeue-Medium"

.inputComercialName
  display: grid
  grid-template-columns: repeat(1, 0.38fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em
  margin-bottom: 1.5em

  @media  (max-width: 600px)
    grid-template-columns: 1fr

.inputsCompanyData
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em
  margin-bottom: 1.5em

  @media  (max-width: 600px)
    grid-template-columns: 1fr

.inputsTaxpayer
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em
  margin-bottom: 1.5em

  @media  (max-width: 600px)
    grid-template-columns: 1fr

.addressWrapper
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: repeat(5, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em
  margin-bottom: 2em

  @media  (max-width: 600px)
    grid-template-columns: 1fr

.blueBtn
  color: $inputBlue
  background-color: transparent
  border-radius: 20px
  height: 40px
  width: max-content
  display: flex
  max-width: 250px
  border: 1px solid $inputBlue
  transition-duration: 300ms
  transition-property: background-color
  transition-timing-function: ease

  &:hover
    border: none
    background-color: #006FB914

.blueBtnIcon
  margin: auto
  margin-left: 1em

.blueBtnText
  margin: auto 1em

.newMember
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  margin: 1em auto

.deleteMember
  color: $blueInputs
  margin: auto 1em
  padding-bottom: 1.5em
  cursor: pointer
  background: none
  border: none
  width: max-content
  height: max-content

.whiteSpace
  @media  (max-width: 600px)
    display: none

.inputReadOnly
  background-color: $inputBgGray
  border-bottom: 1px solid $inputStrongGray
  border-radius: 2px
  padding: 3px
  font-size: 1em
  font-family: 'HelveticaNeue-medium'
  color: $inputGray
  width: 100%
  height: 3.125rem
  outline: none
  overflow: hidden

.bankNameTitle
  font-family: "HelveticaNeue-Medium", sans-serif
  font-size: 1rem
  color: #006fb9
  position: relative
  left: 1rem

.bankName
  max-width: 94%
  font-size: 14px
  height: 3.125rem
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
  position: relative
  left: 1rem
  bottom: 4px

.bankNameTitleDisabled
  font-family: "HelveticaNeue-Medium", sans-serif
  font-size: 1rem
  color: $inputStrongGray
  position: relative
  left: 1rem

.hidden
  visibility: hidden