
@use '../../../utils/sass/_colors.sass' as *

.input
  position: relative
  width: 100%
  border-top-left-radius: 8px
  border-top-right-radius: 8px
  overflow: hidden

.inputText
  border-style: none
  padding-left: 1rem
  padding-top: 1.5rem
  background-color: $inputBgGray
  height: 120px
  resize: none
  outline: none
  font-size: 1rem
  color: $inputGray
  width: 100%
  border-bottom: 1px solid $inputStrongGray

  &:focus
    border-bottom: 1px solid $inputBlue

  .inputText
  @media  (max-width: 600px)
    height: 200px
    padding-top: 3em

.error
  & .inputText
    color: $inputGray
  & .inputTitle
    color: $inputBlue
  & .inputErrorFeedback
    color: $redError
    display: block
  & .inputAditionalInfo
    display: none

.inputAditionalInfo
  padding-left: 1rem
  color: rgb(117, 117, 117)
  font-size: 13px
  letter-spacing: 0.25px
  line-height: 16px
  display: block

.inputErrorFeedback
  padding-left: 1rem
  display: none

.inputTitle
  position: absolute
  height: min-content
  top: 0
  bottom: 0
  left: 1rem
  color: $inputBlue
  transition-property: all
  transition-duration: .5s

.gray
  color: rgb(117, 117, 117)

.blue
  color: $inputBlue
