.WelcomeForm
    width: 100%
    padding-top: 1rem

.w100
    width: 100%
    display: flex

.w100p1
    width: 100%
    display: flex
    padding-top: 0.5rem
    flex-wrap: wrap

.w100p3
    width: 100%
    display: flex
    padding-top: 1rem

.w100p2
    width: 100%
    display: flex
    padding-top: 0.5rem

.w50
    width: 50%
    display: flex
    @media (max-width: 1250px)
        width: 100%

.w10
    width: 10%

.w90
    width: 90%

.BodyForm
    margin-top: 1rem
    width: 100%
    margin-bottom: 1rem

.title
    color: rgb(33, 33, 33)
    font-family: CircularStd-Bold
    font-size: 32px
    line-height: 40px

.trititle
    color: black
    font-family: HelveticaNeue-Medium
    font-size: 16px
    line-height: 24px
    @media (max-width: 1250px)
        display: inline-block

.subtri
    color: rgb(28, 28, 28)
    font-family: HelveticaNeue-Medium
    font-size: 13px
    height: 16px
    line-height: 0px
    @media (max-width: 1250px)
        display: inline-block
        line-height: 12px

.subtriP
    color: rgb(28, 28, 28)
    font-family: HelveticaNeue-Medium
    font-size: 13px
    height: 16px
    line-height: 16px
    padding-top: 0.3rem

.subTitle
    color: rgb(33, 33, 33)
    font-family: CircularStd-Bold
    font-size: 20px
    font-weight: bold
    height: 24px
    line-height: 24px

.subh3
    color: rgb(33, 33, 33)
    font-family: HelveticaNeue-Medium
    font-size: 16px
    display: inline
    height: 24px
    line-height: 24px
    width: 542px

.subh3lighter
    color: rgb(33, 33, 33)
    font-family: HelveticaNeue-Medium
    font-size: 16px
    height: 24px
    line-height: 24px

.bigIcons
    text-align: center
    margin-right: 10px
    width: 36px
    height: 36px
    @media (max-width: 1250px)
        width: 10%
        margin-right: 0px

.bigIconsblank
    text-align: center
    padding-top: 0.5rem
    margin-right: 10px
    width: 36px
    height: 36px

.smallIcons
    text-align: center
    margin-right: 10px

.check
    text-align: center
    margin-right: 10px
    padding-top: 4px

.whiteCard
    background: rgb(255, 255, 255)
    border-radius: 8px
    padding-left: 1rem
    padding-right: 1rem
    padding-bottom: 1rem
    margin-left: -1rem
    margin-right: -1rem
    padding-top: 1rem
    margin-top: 1rem
    box-shadow: 0px 2px 4px 0px rgba(18, 18, 18, 0.08)
    text-align: center

.insideCard
    text-align: left
    margin-right: 20rem
    margin-left: 20rem
    padding-top: 1rem
    @media (max-width: 1250px)
        margin-right: 1rem
        margin-left: 1rem

.heroControls
    height: 6.25rem
    display: flex
    align-items: center
    justify-content: flex-end

.btnBack
    border: lime
    width: 312px
    height: 48px
    background-color: #006FB9
    color: white
    border-radius: 24px
    font-family: CircularStd-Bold
    font-weight: bold
    letter-spacing: .25px
    cursor: pointer

.red
    color: red
    padding-left: 1rem
    display: none
    font-size: 12px

.showResponsive
    display: none
    @media (max-width: 1250px)
        display: inline
