.container
  display: flex
  flex-direction: column
  font-family: 'HelveticaNeue', sans-serif

.subTitle 
  margin: 1rem 0

.error
  color: red

