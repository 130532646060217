.titleAndDescription
  margin-top: 1.5em
  margin-bottom: 1.5em

.secondaryTitle
  font-family: 'CircularStd-Bold'
  margin-bottom: .5em
  font-size: 1.5em

.sectionDescription
  font-size: 1em
  font-family: 'HelveticaNeue-medium'

.inputsTaxpayer
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em
  margin-bottom: 1.5em

  @media(max-width: 600px)
    grid-template-columns: 1fr

.addressWrapper
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: repeat(5, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em

  @media(max-width: 600px)
    grid-template-columns: 1fr

  .whiteSpace
    display: block

    @media(max-width: 600px)
      display: none
.column
  display: flex
  @media(max-width: 600px)
    display: block
  h2
    margin: 0px
    padding-right: 1em

.changeCheckbox
  display: inline-block
  width: 400px
  @media(max-width: 600px)
    width: 100%
    padding-bottom: 0.5em
  padding-right: 10px
  label
    cursor: pointer
    font-size: 1em
    font-weight: 400
    padding-left: 10px
    @media(max-width: 600px)
      padding-left: 0px
    vertical-align: middle
  input
    width: 19.21px
    height: 19.21px
    margin-right: 6.4px
    vertical-align: middle
