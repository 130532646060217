$breakpoints: (sm: 576px, md: 768px,lg: 1024px)
  

@mixin media-breakpoint-down($breakpoint) 
  @media (max-width: (map-get($breakpoints, $breakpoint) - 1px))
    @content



.infoDocuments
  width: 100%
  height: 100%
  background-color: white
  margin: auto 
  display: flex
  border-radius: 1rem
  padding: 3%
  @include media-breakpoint-down(md)
    flex-direction: column
    height: 100%
    overflow: auto

.contactForm
  width: 100%

.title
  font-family: "CircularStd-Bold", sans-serif
  font-size: 36px
  font-weight: 700
  line-height: 44px
  letter-spacing: 0px
  text-align: left


.subtitle
  font-size: 2rem
  font-weight: 600
  margin: 0
  @include media-breakpoint-down(md)
    font-size: 1rem

.extraInfo
  font-size: 1.2rem
  font-weight: 500
  @include media-breakpoint-down(md)
    font-size: .9rem


.recomendations
  font-size: medium
  margin: 0
  margin-top: 1rem
  @include media-breakpoint-down(md)
    font-size: .8rem

.nav
  padding-top: 2rem
  display: flex
  flex-direction: row
  justify-content: space-between
  width: 100%
  @include media-breakpoint-down(md)
    flex-direction: column


.btnFiles
  cursor: pointer
  width: 30%
  max-width: 210px
  height: 40px
  @include media-breakpoint-down(md)
    width: 100%
    max-width: 100%
    height: 3rem
    max-height: 44px
    margin-top: 5%
    margin-bottom: 3%
    font-size: 1rem

.btnFilesBack
  width: 30%
  max-width: 210px
  height: 40px
  @include media-breakpoint-down(md)
    width: 100%
    max-width: 100%
    height: 3rem
    max-height: 44px
    margin-top: 5%
    margin-bottom: 3%
    font-size: 1rem
