@use '../../../utils/sass/_colors.sass' as *

.error
  color: $redError
  display: block
  padding-left: 1rem

.wrapper
  position: relative

.label   
  position: absolute
  color: $inputBlue
  z-index: 1
  top: 0
  left: 1rem
  font-size: 1rem

.hidden 
  display: none

.visible 
 
