@use '../../../utils/sass/_colors.sass' as *

.container 
  width: 62%
  margin: 0 auto
  margin-bottom: 2em
  gap: 16px
  text-align: center
  @media (max-width: 768px)
    width: 100%



.inputTittle
  font-family: "HelveticaNeue", sans-serif
  font-size: 16px
  font-weight: 400
  line-height: 24px
  letter-spacing: 0px
  text-align: left
  color: $inputTitleBlack
  margin-block-end: 0

.buttonContinue
  padding:  0.75rem 1.5rem
  justify-content: center
  align-items: center





.ulrText
  font-family: "HelveticaNeue", sans-serif
  font-size: 14px
  font-weight: 400
  line-height: 22px
  letter-spacing: 0px
  text-align: center
  color: $inputBlue
  margin-left: auto
  margin-right: auto




.child 
  width: 515px
  min-width: 35%
  max-width: 80%
  margin: 0 auto


.title
  font-family: "CircularStd-Bold"
  font-size: 2rem !important

.subtitle
  font-size: .8125rem
  font-family: "HelveticaNeue-Medium" , sans-serif

.secondaryTitle
  font-family: "CircularStd-Bold"

.inputsSection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 1.5em
  margin-bottom: 2em
  grid-row-gap: 2em

  @media (max-width: 600px)
    grid-template-columns: 1fr
    margin-bottom: 2em

.radiosSection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5rem
  grid-row-gap: 2em
  margin-bottom: 2em

  @media (max-width: 600px)
    grid-template-columns: 1fr
.phoneRadios
  grid-row: 1/2

.emailRadios
  grid-row: 1/2

.heroControls
  height: 6.25rem
  display: flex
  align-items: center
  justify-content: space-between

.btnBack
  visibility: hidden
  color: $main-blue
  border-style: none
  font-weight: bold
  letter-spacing: .25px
  background-color: transparent
  cursor: pointer

@media(max-width: 600px)
  .btnBack
    display: none

.btnNext
  width: 312px
  height: 48px
  background: rgb(224, 224, 224)
  border-radius: 24px
  border-style: solid
  font-weight: bold
  letter-spacing: .25px
  cursor: pointer

  @media(max-width: 600px)
    margin: auto

.active
  background-color: $inputBlue
  color: white

.column
  display: flex
  h2
    margin: 0px
    padding-right: 1em
  @media(max-width: 600px)
    display: block
.changeCheckbox
  display: inline-block
  width: 400px
  @media(max-width: 600px)
    width: 100%
    padding-bottom: 0.5em
  padding-right: 10px
  label
    cursor: pointer
    font-size: 1em
    font-weight: 400
    padding-left: 10px
    @media(max-width: 600px)
      padding-left: 0px
    vertical-align: middle
  input
    width: 19.21px
    height: 19.21px
    margin-right: 6.4px
    vertical-align: middle

.btnContainer
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin: 36px 0 36px 0
  padding: 0 28%

.errorContainer
  background-color: #FFF0EF
  width: 100%
  border: 2px solid #BA4B44
  padding: 16px
  border-radius: 8px
  gap: 8px
  align-content: start
  text-align: start
  display: flex


.errorMessage
  font-family: "HelveticaNeue" , sans-serif
  font-size: 14px
  font-weight: 400
  line-height: 24px
  letter-spacing: 0em
  text-align: start
  color: #BA4B44
  flex-grow: 1


.errorIcon
  color: #BA4B44
  font-size: 1rem
  align-items: start


.list
    li
        margin: 30px 0 10px 0
        font-family: "HelveticaNeue-Medium", sans-serif
        font-size: 16px
        font-weight: 400
        line-height: 24px
        color: #000000
        text-align: left
        
.modalBtn
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 40px 0 20px 0
    padding: 0 40%
    @media (max-width: 768px)
        padding: 0 20%
    @media (max-width: 576px)
        padding: 0 10%

.buttonText
    color: $blueInputs
    font-family: "HelveticaNeue", sans-serif
    text-decoration: underline
    font-weight: 700
    background: none
    border: none
    cursor: pointer