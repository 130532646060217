$header-bg-color: #ffdd30

.header
  max-height: 5.625rem
  height: 5.625rem
  background-color: $header-bg-color


.header
  padding: 0px 10%
  @media(max-width: 600px)
    padding: 0px 5%

.header__logos
  display: flex

.buttonCustomer
  width: 132px
  height: 48px
  cursor: pointer

.headerWelcome
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  width: 100%

.hiddenElement
  display: none