@use '../../../utils/sass/_colors.sass' as *

$mobileView: 1023px

.heroControls
    height: 6.25rem
    display: flex
    align-items: center
    justify-content: flex-end

.btnNext
  width: 312px
  height: 48px
  background: rgb(224, 224, 224)
  border-radius: 24px
  border-style: solid
  font-weight: bold
  letter-spacing: .25px
  cursor: pointer

  @media(max-width: $mobileView)
    margin: auto


.titleh2
    font-family: "CircularStd-Bold"
    font-size: 28px
    font-weight: 700
    line-height: 36px
    letter-spacing: 0em
    text-align: center


.titleh3
    font-family: "CircularStd-Bold"
    font-size: 24px
    font-weight: 700
    line-height: 32px
    letter-spacing: 0em
    text-align: center


.titleh4
    font-family: "CircularStd-Bold"
    font-size: 20px
    font-weight: 700
    line-height: 28px
    letter-spacing: 0em
    text-align: center


.container
    display: flex
    flex-direction: row
    width: 100%
    @media(max-width: $mobileView)
        margin: 1rem auto

    .physical, .moral
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        width: 100%
        div
            width: 100%
            max-width: 529px
            &:not(:last-child)
                margin-bottom: 48px
            @media(max-width: $mobileView)
                max-width: 100%
            
            

    .divider
        border-left: 2px solid #BDBDBD
        margin-left: 4rem
        margin-right: 4rem
        margin-top: 3rem
        margin-bottom: 4.5rem
    @media(max-width: $mobileView)
        .divider
            width: 85%
            border-bottom: 2px solid #BDBDBD
            margin: 2rem 0
            
            
                    
    @media(max-width: $mobileView)
        flex-direction: column
        align-items: center
        justify-content: center
        
        
.containerFyi
    margin-top: 3rem
    h3
        margin: 4.5rem 0
    @media(max-width: $mobileView)
        margin: 2rem auto
        width: 100%
        max-width: 100%
    
    .fyi
        display: flex
        flex-direction: row
        justify-content: space-evenly
        margin-bottom: 5rem
        gap: 2%
        div
            width: 100%

        @media(max-width: $mobileView)
            flex-direction: column
            align-items: center
            justify-content: center
            gap: 0

            div
                &:not(:last-child)
                    margin-bottom: 48px
    
.additionalData
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin: auto
    margin-top: 2rem
    max-width: 529px
    h3
        margin-top: 4.5rem
        margin-bottom: 4rem
    @media(max-width: $mobileView)
        margin: 2rem auto
        width: 100%
        max-width: 100%

    div
        width: 100%
        &:not(:last-child)
            margin-bottom: 48px

.mainForm
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%


.buttonText
    color: $blueInputs
    font-family: "HelveticaNeue"
    text-decoration: underline
    font-weight: 700
    background: none
    border: none
    cursor: pointer

.bodyText
    font-family: "HelveticaNeue"
    font-size: 16px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0em
    text-align: center

.logoCoppelpay
    width: 324px
    height: 154px
    @media(max-width: $mobileView)
        width: 50%
        height: auto
        min-width: 145px
        min-height: 69px
