.wrapper
  font-size: 1rem
  display: grid
  grid-template-columns: 25% 75%
  grid-template-rows: 40% 60%
  max-width: 250px

.checkbox
  margin: auto
  width: 1em
  grid-column: 1/2
  grid-row: 1/2

.title
  font-size: 1em
  grid-column: 2/3
  grid-row: 1/2
  margin: auto 0

.description
  font-size: .8125em
  grid-column: 2/3
  grid-row: 2/3
