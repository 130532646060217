@use '../../../utils/sass/_colors.sass' as *

$breakpoints: (sm: 576px, md: 768px,lg: 1024px)

@mixin media-breakpoint-down($breakpoint)
  @media (max-width: (map-get($breakpoints, $breakpoint) - 1px))
    @content

@mixin media-breakpoint-up($breakpoint)
  @media (min-width: map-get($breakpoints, $breakpoint))
    @content

.overlay
  width: 100%
  height: 100%
  margin: auto
  display: flex

.modal
  width: 100%
  height: 100%
  background-color: white
  margin: auto
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: 5% 77% 18%
  border-radius: 1rem
  padding: 3%

.main
  display: grid
  grid-template-columns: 40% 60%
  grid-template-rows: 100%
  padding: 1rem 0
  @include media-breakpoint-down(lg)
    display: flex
    flex-direction: column
    height: 100%

.image
  display: flex
  margin: auto
  width: 300px
  height: 100%
  justify-content: center
  align-items: center

.title
  font-family: "CircularStd-Bold", sans-serif
  font-size: 36px
  font-weight: 700
  line-height: 44px
  letter-spacing: 0px
  text-align: left


.placeholder
  width: 100%
  height: 100%

.controls
  display: flex
  justify-content: space-between
  padding: 1rem
  width: inherit
  height: 100%
  min-height: 100px

  @include media-breakpoint-down(lg)
    flex-direction: column
    margin-right: 0
    margin-left: 0
  @include media-breakpoint-up(lg)
    margin-top: 2%
  @media(max-width: 600px)
    flex-direction: column-reverse

.control__next
  height: 50%
  width: 130px
  margin-top: auto
  margin-bottom: auto
  cursor: pointer
  @media(max-width: 600px)
    margin-top: 12px 
    width: 100%

.control__prev
  height: 50%
  width: 130px
  margin-top: auto
  margin-bottom: auto
  cursor: pointer
  @media(max-width: 600px)
    margin-top: 12px
    width: 100%

.btnUploadFile
  margin: 0.5em
  width: 271px
  height: 48px
  font-family: "HelveticaNeue-Bold", sans-serif
  cursor: pointer
  margin-top: auto
  margin-bottom: auto

  @media(max-width: 600px)
    width: 100%

.stepper
  display: flex
  justify-content: center
  align-items: center
  margin-top: 1rem
  @include media-breakpoint-down(lg)
    display: none

.stepList
  list-style-type: none
  border-radius: 10px
  display: flex
  position: relative
  z-index: 10
  padding: 20px 10px

.stepItem
  padding: 0 10px
  flex-basis: 0
  -webkit-box-flex: 1
  -ms-flex-positive: 1
  flex-grow: 1
  max-width: 100%
  display: flex
  flex-direction: column
  text-align: center
  position: relative

.stepNumber
  height: 15px
  width: 15px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  margin: 0 auto
  position: relative
  z-index: 10
  background-color: #D9E3F2

.activo
  height: 17px
  width: 17px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  margin: 0 auto
  position: relative
  z-index: 10
  background-color: #006fb9

.hide
  visibility: hidden

.reupload
  width: 60%
