.radioGroup
  font-size: 1rem
  margin-bottom: 2em

.groupTitle
  margin-bottom: 1em
  font-size: 1.4375em
  font-family: 'CircularStd-Bold', sans-serif

.options
  display: flex
  flex-direction: column

.option
  margin-right: 1em
  padding-bottom: 1em

.option > input
  margin-right: 1em
