@use '../../utils/sass/_colors.sass' as *

$breakpoints: (sm: 576px, md: 768px,lg: 1024px)
  
@mixin media-breakpoint-down($breakpoint) 
  @media (max-width: (map-get($breakpoints, $breakpoint) - 1px))
    @content

.btnLight
  border: 1px solid $inputBlue
  color: $inputBlue
  border-radius: 100px
  background-color: white
  cursor: pointer

  @include media-breakpoint-down(lg)
    width: 100%
  
