@use '../../../utils/sass/_colors.sass' as *

.contactForm
  font-size: 1rem
  width: 100%
  padding: 1.5rem
  background-color: white
  box-shadow: 0px 2px 4px 0px rgba(18, 18, 18, 0.08)
  border-radius: 8px

.title
  font-family: "CircularStd-Bold"
  font-size: 2rem !important

.subtitle
  font-size: .8125rem
  font-family: "HelveticaNeue-Medium"

.secondaryTitle
  font-family: "CircularStd-Bold"

.inputsSection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-gap: 1.5em
  margin-bottom: 2em
  grid-row-gap: 2em

  @media (max-width: 600px)
    grid-template-columns: 1fr
    margin-bottom: 2em

.radiosSection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5rem
  grid-row-gap: 2em
  margin-bottom: 2em

  @media (max-width: 600px)
    grid-template-columns: 1fr
.phoneRadios
  grid-row: 1/2

.emailRadios
  grid-row: 1/2

.heroControls
  height: 6.25rem
  display: flex
  align-items: center
  justify-content: space-between

.btnBack
  visibility: hidden
  color: $main-blue
  border-style: none
  font-weight: bold
  letter-spacing: .25px
  background-color: transparent
  cursor: pointer

@media(max-width: 600px)
  .btnBack
    display: none

.btnNext
  width: 312px
  height: 48px
  background: rgb(224, 224, 224)
  border-radius: 24px
  border-style: solid
  font-weight: bold
  letter-spacing: .25px
  cursor: pointer

  @media(max-width: 600px)
    margin: auto

.active
  background-color: $inputBlue
  color: white

.column
  display: flex
  h2
    margin: 0px
    padding-right: 1em
  @media(max-width: 600px)
    display: block
.changeCheckbox
  display: inline-block
  width: 400px
  @media(max-width: 600px)
    width: 100%
    padding-bottom: 0.5em
  padding-right: 10px
  label
    cursor: pointer
    font-size: 1em
    font-weight: 400
    padding-left: 10px
    @media(max-width: 600px)
      padding-left: 0px
    vertical-align: middle
  input
    width: 19.21px
    height: 19.21px
    margin-right: 6.4px
    vertical-align: middle

