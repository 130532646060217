@use '../../../../../utils/sass/_colors.sass' as *

$breakpoints: (sm: 576px, md: 768px,lg: 1024px, xl: 1200px, xxl: 1400px)
  

@mixin media-breakpoint-down($breakpoint) 
  @media (max-width: (map-get($breakpoints, $breakpoint) - 1px))
    @content

@mixin media-breakpoint-up($breakpoint)
  @media (min-width: map-get($breakpoints, $breakpoint))
    @content

.fileInput
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center

.title
  font-family: "CircularStd-Bold", sans-serif
  font-size: 28px
  weight: 700
  line-height: 36px

.descriptionItem
  font-family: "HelveticaNeue", sans-serif
  font-size: 1rem
  margin-bottom: .5rem

.mainContent
  display: flex
  align-items: center
  height: auto
    
.file
  display: flex
  flex-direction: column
  width: 100%


.fileName
  display: flex
  flex-direction: row
  align-items: center
  margin: 1rem
  

.fileBtn
  @include media-breakpoint-down(md)
    display: flex
    width: 100%

.fileName .name
  margin-left: 20%
  margin-right: 1em
  max-width: 350px
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  @include media-breakpoint-up(sm)
    max-width: 300px
    margin-left: 25%
  @include media-breakpoint-up(md)
    max-width: 250px
  @include media-breakpoint-up(xxl)
    max-width: 200px
  @include media-breakpoint-up(lg)
    max-width: 140px
    margin-left: 5px


.close
  color: gray
  font-size: 1.5rem
  cursor: pointer
  &:hover
    color: $inputBlue


.fileError
  color: #BA4B44
  font-size: 16px
  font-family: "HelveticaNeue", sans-serif
  padding-left: 5%
  display: flex
  flex-direction: column

.stepper
  display: none
  @include media-breakpoint-down(md)
    display: flex
    justify-content: center
    align-items: center
    margin-top: 1rem

.stepList
  list-style-type: none
  border-radius: 10px
  display: flex
  position: relative
  z-index: 10
  padding: 20px 10px

.stepItem
  padding: 0 10px
  flex-basis: 0
  -webkit-box-flex: 1
  -ms-flex-positive: 1
  flex-grow: 1
  max-width: 100%
  display: flex
  flex-direction: column
  text-align: center
  position: relative

.stepNumber
  height: 10px
  width: 10px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  margin: 0 auto
  position: relative
  z-index: 10
  background-color: #D9E3F2

.activo
  height: 12px
  width: 12px
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  margin: 0 auto
  position: relative
  z-index: 10
  background-color: #006fb9

.divLocalErrors
  display: flex
  flex-direction: row
  @include media-breakpoint-down(md)
    flex-direction: column
    align-items: center
  
.divErrors
  display: flex
  flex-direction: row-reverse
  width: 85%
  @include media-breakpoint-down(md)
    flex-direction: column
    align-items: center
    width: 100%

.divButtonName
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  width: 85%
  @include media-breakpoint-down(md)
    flex-direction: column
    align-items: center
    width: 100%
