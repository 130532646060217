.passwordValidationsChecklist
    display: flex
    flex-direction: column
    .checkListTittle
        font-weight: 700
        font-size: 16px
        line-height: 24px
        margin: 0

    .checkListItem
        display: flex
        flex-direction: row
        margin: 0
        align-items: center
        justify-items: center
        p
            margin: 0 0 0 8px
        .icon
            stroke-width: 80
            stroke: #ccc
            color: #ccc
        .iconValid
            stroke-width: 80
            stroke: #2E8241
            color: #2E8241
