@use '../../../../utils/sass/_colors.sass' as *

.content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    position: relative

.recoveryForm
    width: 593px
    height: 631px
    padding-top: 1rem
    @media(max-width: 600px)
        width: 312px
        padding-top: 0rem

.imageContainer
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.bodyForm
    margin-top: 1rem
    width: 100%
    margin-bottom: 1rem

.titleContainer
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.title
    color: #1B1A16
    font-family: CircularStd-Bold
    font-size: 28px
    line-height: 36px
    margin: 2rem 0 2rem  0
    @media(max-width: 600px)
        color: #1B1A16
        font-family: CircularStd-Bold
        font-weight: 700
        font-size: 20px
        line-height: 28px
        margin: 2rem 0 2rem  0


.subtitleRecovery
    font-family: "HelveticaNeue"
    font-size: 16px
    line-height: 24px
    margin-bottom: 1rem
    font-weight: 400
    @media(max-width: 600px)
        width: 311px
        font-family: "HelveticaNeue"
        font-size: 16px
        line-height: 24px
        margin-bottom: 1rem


.divAlert
    display: flex
    widt: 100%
    border-radius: 8px
    border-style: solid
    border-width: 2px
    border-color: #145897
    background-color: #EAF7FF
    vertical-align: middle
    margin-bottom: 1.5rem

.col1
    width: 4%
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 1.2rem
    margin-left: 1rem
    color: #145B97


.col2
    width: 96%

.alert
    font-family: HelveticaNeue
    color: #145897
    font-size: 16px
    font-weight: 500
    line-height: 20px
    letter-spacing: 0.37px
    margin-top: 1rem
    margin-bottom: 1rem
    margin-left: 1rem
    margin-right: 2rem


.labelEmail
    color: #1B1A16
    font-family: HelveticaNeue
    font-size: 16px
    line-height: 24px
    margin-bottom: 0.5rem

.inputEmail
    width: 100%
    height: 40px
    border-radius: 8px
    border-style: solid
    border-width: 1px
    border-color: #BDBDBD
    padding-left: 1rem
    font-family: HelveticaNeue
    font-size: 16px
    line-height: 24px
    font-weight: 400
    color: rgb(33, 33, 33)
    margin-bottom: 1rem

.inputEmail::placeholder
    color: #BDBDBD

.btnContainer
    margin-top: 2rem
    display: flex
    flex-direction: column
    align-items: center

.btnAceptar
  width: 96px
  height: 48px
  border-radius: 100px
  background: #006FB9
  border-color: #006FB9
  border-width: 0px
  font-family: HelveticaNeue
  font-weight: bold
  font-size: 16px
  font-weight: 700
  line-height: 24px
  color: white
  cursor: pointer
  @media(max-width: 600px)
    margin: auto

.btnRegresarContainer
    margin-top: 1rem
    position: absolute
    top: 0
    left: 0
    @media(max-width: 600px)
        margin-top: 2rem
        position: absolute
        top: 0
        left: 0

        
.btnRegresar
    background: none
    border: none
    padding: 0
    font-family: HelveticaNeue-Bold
    font-size: 16px
    line-height: 19.36px
    cursor: pointer

.btnRegresar img
    width: 24px
    height: 24px  

.active
  background-color: $blueInputs
  color: white
  border: none

.imageLogo 
    @media(max-width: 600px)
        width: 145px
        height: 68.91px
        left: 5rem

.linkContainer
    align-items: left
    justify-content: left
    margin-top: 1rem
    
.link
    display: inline
    border: 0
    padding: 0
    margin: 0
    text-decoration: underline
    background-color: none
    background: none
    color:#006FB9
    font-family: HelveticaNeue
    font-size: 16px
    line-height: 24px
    font-weight: 700

.link:hover
    text-decoration: none
    background-color: none
    background: none
    color:#006FB9
    cursor: pointer

.linkClock
    display: inline
    border: 0
    padding: 0
    margin: 0
    text-decoration: none
    background-color: none
    background: none
    color:#595955
    font-family: HelveticaNeue
    font-size: 14px
    line-height: 20px
    font-weight: 500

strong
    color: #1B1A16
    font-family: HelveticaNeue-Bold
    font-size: 16px
    line-height: 24px
    margin-bottom: 1rem
