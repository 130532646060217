@use '../../../utils/sass/_colors.sass' as *

.important > div > h4
  font-size: 1.5em !important

.contactForm
  font-size: 1rem
  width: 100%
  padding: 1.5em
  background-color: white
  box-shadow: 0px 3px 6px rgba(18, 18, 18, 0.16)
  border-radius: 8px
  overflow: hidden

.title
  font-family: "CircularStd-Bold"
  font-size: 2rem

.subtitle
  font-size: .8125rem
  font-family: "HelveticaNeue-Medium"

.secondaryTitle
  font-family: "CircularStd-Bold"
  margin-top: 1em

.inputsSection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  margin-bottom: 2em

  @media screen and (max-width: 600px)
    grid-template-columns: 1fr
    margin: 1em auto

.radiosSection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  row-gap: 1.5em
  margin: 2.2em auto

  @media screen and (max-width: 600px)
    grid-template-columns: 1fr
    row-gap: 3em
    margin: 2em auto

.addressWrapper
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: repeat(5, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em

  @media (max-width: 600px)
    grid-template-columns: 1fr
    grid-template-rows: auto
    margin: 1em auto

.phoneRadios
  grid-row: 1/2

.emailRadios
  grid-row: 1/2

.checkboxWrapper
  display: flex
  width: 100%

  @media (max-width: 600px)
    flex-direction: column

.checkboxWrapper > :nth-child(n)
  margin-right: 1em

.selectACheckMessage
  margin: auto
  color: red

.heroControls
  height: 6.25rem
  display: flex
  align-items: center
  justify-content: space-between

.btnBack
  color: $main-blue
  border-style: none
  font-weight: bold
  letter-spacing: .25px
  background-color: transparent
  cursor: pointer

@media(max-width: 600px)
  .btnBack
    display: none

.btnNext
  width: 312px
  height: 48px
  background: rgb(224, 224, 224)
  border-radius: 24px
  border-style: solid
  font-weight: bold
  letter-spacing: .25px
  cursor: pointer

  @media(max-width: 600px)
    margin: auto

  //address
.addressFromOfficialData
  font-size: 1rem
  margin: 0
  margin-left: 2em
  padding: 0

.addressTitle
  font-weight: bold

.whiteSpace
  height: 1px
  @media (max-width: 600px)
    display: none

.selectWrapper
  max-width: 300px

  @media(max-width: 600px)
    max-width: 100%

.active
  background-color: $inputBlue
  color: white

.column
  display: flex
  @media(max-width: 600px)
    display: block
  h3
    margin: 0px
    padding-right: 1em
.changeCheckboxIntegration
  display: inline-block
  white-space: nowrap
  width: 400px
  padding-right: 10px
  cursor: pointer
  font-size: 1em
  font-weight: 400
  padding-left: 10px
  vertical-align: middle
  label
    width: 150px
    white-space: break-spaces
  p
    width: 280px
    white-space: break-spaces
    padding-left: 32px
  input
    width: 24px
    height: 24px
    margin-right: 8px
    vertical-align: middle
    
.changeCheckbox
  display: inline-block
  width: 400px
  @media(max-width: 600px)
    width: 100%
    padding-bottom: 0.5em
    padding-left: 0px
  padding-right: 10px
  label
    cursor: pointer
    font-size: 1em
    font-weight: 400
    padding-left: 10px
    vertical-align: top
    @media(max-width: 600px)
      padding-left: 0px
  input
    width: 19.21px
    height: 19.21px
    margin-right: 6.4px
    vertical-align: middle

.childrenInfoModal
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  font-display: "HelveticaNeue-Medium"
  font-size: 16px
  font-weight: 400
  line-height: 24px
  p
    margin: 0
    padding: 0
    text-align: center
    color: #1B1A16
  a
    color: #006FB9
    text-decoration: none
    font-weight: 400


.modalBtn
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 25px 0 20px 0
    padding: 0 40%
    @media (max-width: 768px)
        padding: 0 20%
    @media (max-width: 576px)
        padding: 0 10%