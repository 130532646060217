@use '../../../../utils/sass/_colors.sass' as *

.titleAndDescription
  margin-top: 1.5em
  margin-bottom: 1.5em

.secondaryTitle
  font-family: 'CircularStd-Bold'
  margin-bottom: .5em
  font-size: 1.5em

.sectionDescription
  font-size: 1em
  font-family: 'HelveticaNeue-medium'

.inputsTaxpayer
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em
  margin-bottom: 1.5em

  @media(max-width: 600px)
    grid-template-columns: 1fr

.addressWrapper
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: repeat(5, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em

  @media(max-width: 600px)
    grid-template-columns: 1fr

.blueBtn
  color: $inputBlue
  background-color: transparent
  border-radius: 20px
  height: 40px
  width: max-content
  display: flex
  max-width: 250px
  border: 1px solid $inputBlue
  transition-duration: 300ms
  transition-property: background-color
  transition-timing-function: ease

  &:hover
    border: none
    background-color: #006FB914

.blueBtnIcon
  margin: auto
  margin-left: 1em

.blueBtnText
  margin: auto 1em

.newMember
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  margin: 1em auto

  @media(max-width: 600px)
    grid-template-columns: 1fr

.deleteMember
  color: $blueInputs
  margin: auto 1em
  padding-bottom: 1.5em
  cursor: pointer
  background: none
  border: none
  width: max-content
  height: max-content

.column
  display: flex
  @media(max-width: 600px)
    display: block
  h2
    margin: 0px
    padding-right: 1em
.changeCheckbox
  display: inline-block
  width: 400px
  @media(max-width: 600px)
    width: 100%
    padding-bottom: 0.5em
  padding-right: 10px
  label
    cursor: pointer
    font-size: 1em
    font-weight: 400
    padding-left: 10px
    @media(max-width: 600px)
      padding-left: 0px
    vertical-align: middle
  input
    width: 19.21px
    height: 19.21px
    margin-right: 6.4px
    vertical-align: middle