@use '../../../utils/sass/_colors.sass' as *

.inputContainer
    display: flex
    flex-direction: column
    margin: 10px 0

.inputContainer > label
    margin-bottom: 5px
    font-family: "HelveticaNeue-Medium", sans-serif
    font-size: 16px
    font-weight: 400
    line-height: 24px
    letter-spacing: 0px
    text-align: left
    color: $inputTitleBlack
    margin-block-end: 0


.inputWrapper
    display: flex
    align-items: center
    width: 100%
    border: 1px solid #ccc
    outline: none
    border-radius: 8px
    input
        border: none
        padding: 8px 16px
        font-size: 1.1rem
        outline: none
        width: 100%
        border-radius: 5px
        background-color: transparent
        color: #1f1f1f
        &::placeholder
            color: #BDBDBD
            font-family:  "HelveticaNeue-Medium", sans-serif
            font-size: 16px
            font-weight: 400
            line-height: 24px
            letter-spacing: 0px
            text-align: left
    .icon
        margin-right: 10px
        font-size: 1.2rem
        color: #1B1A16
    .errorIcon
        margin-right: 10px
        font-size: 1.2rem
        color: #BA4B44
    .validIcon
        margin-right: 10px
        font-size: 1.2rem
        color: #2E8241

.inputWrapperError
    border: 2px solid #BA4B44

.validEmail
    border: 2px solid #2E8241

.errorText
    margin-top: 3px
    color: #BA4B44
    font-size: 14px
    font-weight: 400
    line-height: 22px
    font: Helvetica Neue
    text-align: left


.checkListItem
        display: flex
        flex-direction: row
        margin: 8px 0 0 0 
        align-items: center
        justify-items: center
        p
            margin: 0 0 0 8px
        .icon
            stroke-width: 80
            stroke: #ccc
            color: #ccc
        .iconValid
            stroke-width: 80
            stroke: #2E8241
            color: #2E8241

@media(max-width: 600px)
    .checkListItem
        margin-top: 16px
        margin-left: 15%
