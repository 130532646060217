.closeBtn
    position: absolute
    top: 11px
    right: 11px
    background: none
    border: none
    font-size: 25px
    cursor: pointer
    color: #bebebd
    padding: 0 10px
    

.tittle
    font-size: 24px
    font-weight: 700
    padding: 0
    line-height: 36px
    text-align: center
    font-family: "CircularStd-Bold" , sans-serif

.imgContainer
    display: flex
    justify-content: center
    align-items: center
    margin: 0 auto
    width: 100%
    height: 100%
    max-width: 150px
    max-height: 150px
    margin-bottom: 20px

.children
    margin: 0 
    text-align: center
    font-size: 16px
    line-height: 24px
    font-weight: 500
    font-family: "HelveticaNeue" , sans-serif

.btnContainer
    display: flex
    justify-content: center
    align-items: center
    margin-top: 20px
    margin: 0 auto
    width: 100%
    height: 100%
    max-width: 120px
    max-height: 120px
    margin-bottom: 20px