@use '../../utils/sass/_colors.sass' as *

#root
  display: flex
  flex-direction: column
  justify-content: space-between
  width: 100%
  height: 100%

.rootContent
  display: flex
  flex-direction: column
  min-height: 100vh

.mainContainer
  display: grid
  grid-template-rows: auto 1fr auto
  height: 100%

.steps
  margin: 2em 10%

@media screen and (max-width: 600px)
  .steps
    display: none

.hero
  padding: 20px 10%
  font-size: .875rem
  flex: 1

.bgWhite
  background-color: white

.bgGray
  background-color: $grayBg
