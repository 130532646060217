.inputsContainer
    padding: 0 15%
    @media (max-width: 768px)
        padding: 0 7%
    @media (max-width: 576px)
        padding: 0 0

.alertErrorContainer
    padding: 0 15%
    max-width: 488px
    @media (max-width: 768px)
        padding: 0 7%
    @media (max-width: 576px)
        padding: 0 0
    .alertError
        display: flex
        width: 100%
        border-radius: 8px
        border-style: solid
        border-width: 2px
        border-color: #BA4B44
        background-color: #FFF0EF
        vertical-align: middle
        margin-bottom: 1.5rem
        .col1
            width: 4%
            display: flex
            flex-direction: column
            align-items: center
            margin-top: 1.2rem
            margin-left: 1rem
        .col2
            width: 96%
        .alert
            font-family: "HelveticaNeue-Medium", sans-serif
            color: #BA4B44
            font-size: 16px
            font-weight: 500
            line-height: 20px
            letter-spacing: 0.37px
            margin-top: 1rem
            margin-bottom: 1rem
            margin-left: 1rem
            margin-right: 2rem
            padding-top: 5px

.checkboxWrapper
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    margin: 36px 0 10px 0
    p
        margin: 0 0 0 16px
        font-family: "HelveticaNeue-Medium", sans-serif
        font-size: 16px
        font-weight: 400
        line-height: 24px
        color: #000000
        letter-spacing: 1px
        text-align: left
        margin-block-end: 0
        a
            color: #006FB9
            text-decoration: underline
            cursor: pointer
            font-weight: 700
            &:hover
                color: #004A80

.recaptchaContainer
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    margin: 26px 0

.btnContainer
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 10px 0 36px 0
    padding: 0 35%
    @media (max-width: 768px)
        padding: 0 20%
    @media (max-width: 576px)
        padding: 0 10%

.checkListValidation
    margin: 24px 0 26px 0

.list
    li
        margin: 30px 0 10px 0
        font-family: "HelveticaNeue-Medium", sans-serif
        font-size: 16px
        font-weight: 400
        line-height: 24px
        color: #000000
        text-align: left

.modalBtn
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    margin: 40px 0 20px 0
    padding: 0 40%
    @media (max-width: 768px)
        padding: 0 20%
    @media (max-width: 576px)
        padding: 0 10%
