.closeBtn
    position: absolute
    top: 11px
    right: 11px
    background: none
    border: none
    font-size: 25px
    cursor: pointer
    color: #bebebd
    padding: 0 10px
    &:hover
        color: #1B1A16
        transition: all 0.3s ease-in-out
    
.header
    margin: 10px 0
    text-align: center

.tittle
    font-size: 28px
    font-weight: 700
    margin-top: 30px
    padding: 0
    line-height: 36px

.children
    margin: 0 
    text-align: left
    font-size: 16px
    line-height: 24px
    font-weight: 500
