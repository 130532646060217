/* //To make sure footer is always at the botom of the view */
#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
#form {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: "HelveticaNeue-Medium", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("./fonts/Helvetica Neue/helveticaneue.ttf");
}

@font-face {
  font-family: "HelveticaNeue-Bold";
  src: url("./fonts/Helvetica Neue/helveticaneue-bold.ttf");
}

@font-face {
  font-family: "HelveticaNeue-Medium";
  src: url("./fonts/Helvetica Neue/helveticaneue-medium.ttf");
}

@font-face {
  font-family: "CircularStd-Bold";
  src: url("./fonts/CircularStd/CircularStd-Bold.ttf");
}

@font-face {
  font-family: "CircularStd-Black";
  src: url("./fonts/CircularStd/CircularStd-Black.otf");
}

@font-face {
  font-family: "CircularStd-Book";
  src: url("./fonts/CircularStd/CircularStd-Book.otf");
}

@font-face {
  font-family: "CircularStd-BookItalic";
  src: url("./fonts/CircularStd/CircularStd-BookItalic.otf");
}

@font-face {
  font-family: "CircularStd-MediumItalic";
  src: url("./fonts/CircularStd/CircularStd-MediumItalic.otf");
}

@font-face {
  font-family: "CircularStd-BlackItalic";
  src: url("./fonts/CircularStd/CircularStd-BlackItalic.otf");
}

/* IMPORTANT: */
/* To make sure CircularStd don't get too much word spacing */
body {
  font-variant: proportional-nums !important;
  font-feature-settings: "pnum" !important;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #006fb9;
  border-color: #006fb9;
}



.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  color: #212121;
  font-size: 16px;
}

.ant-steps-small .ant-steps-item-icon {
  width: 25px;
  height: 25px;
  margin: 0 8px 0 0;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  border-radius: 24px;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #212121;
  font-size: 16px;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
  color: #fff;
  font-size: 16px;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background: #006fb9;
  border-color: #006fb9;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: white;
}

.ant-steps-small .ant-steps-item-title {
  font-size: 16px;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
  background-color: rgb(189, 189, 189);
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
  background-color: rgb(189, 189, 189);
}

.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #d9e3f2;
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
  color: #145B97;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
  color: #145B97;
}

.ant-select-selector {
  position: relative;
  background-color: #f9f9f9 !important;
  border-radius: 0px !important;
  border: 0px !important;
  border-bottom: 1px solid #636363 !important;
  height: 50px !important;
  padding-top: 16px !important;
  padding-left: 16px !important;
  font-family: "HelveticaNeue-Medium";
}

.ant-select-item {
  min-height: 50px !important;
  padding-top: 16px !important;
  padding-left: 16px !important;
  color: #757575 !important;
  font-family: "HelveticaNeue-Medium" !important;
}

.ant-select-item-option-content {
  color: #757575 !important;
  font-family: "HelveticaNeue-Medium" !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #757575 !important;
  font-family: "HelveticaNeue-Medium" !important;
  font-size: 15px !important;
}

.ant-select-arrow {
  top: 55% !important;
}