@use '../../../utils/sass/_colors.sass' as *

.radioWrapper
  font-size: 1rem
  margin-bottom: 2em

.title
  margin-bottom: 1em
  font-size: 1.4375rem
  font-family: 'CircularStd-Bold', sans-serif

.options
  display: flex
  flex-direction: column

.option
  padding-bottom: 1em
  margin-right: 1em

.inputErrorFeedback
  color: $redError
