@use '../../../utils/sass/_colors.sass' as *

.content
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    width: 100%
    height: 100%
    position: relative

.recoveryForm
    width: 328px
    height: 631px
    padding-top: 1rem

.bodyForm
    margin-top: 1rem
    width: 100%
    margin-bottom: 1rem

.titleContainer
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center

.titleRecover
    margin: 2.5rem 0
    color: rgb(33, 33, 33)
    font-family: CircularStd-Bold
    font-size: 28px
    line-height: 36px
    margin: 2rem 0 2rem  0
    text-align: center
    @media(max-width: 600px)
        color: rgb(33, 33, 33)
        font-family: CircularStd-Bold
        font-weight: 700
        font-size: 20px
        line-height: 28px
        margin: 2rem 0 2rem  0

.labelEmail
    color: #1B1A16
    font-family: HelveticaNeue
    font-size: 16px
    line-height: 24px
    margin-bottom: 0.5rem

.inputEmail
    width: 100%
    height: 40px
    border-radius: 8px
    border-style: solid
    border-width: 1px
    border-color: #BDBDBD
    padding-left: 1rem
    font-family: HelveticaNeue
    font-size: 16px
    line-height: 24px
    font-weight: 400
    color: rgb(33, 33, 33)
    margin-bottom: 1rem

.inputEmail::placeholder
    color: #BDBDBD

.reCaptchaContainer
    display: flex
    flex-direction: column
    align-items: center

.btnContainer
    margin-top: 2rem
    display: flex
    flex-direction: column
    align-items: center

.btnEnviar
  width: 96px
  height: 48px
  border-radius: 100px
  background: #BDBDBD
  border-color: #BDBDBD
  border-width: 0px
  font-family: HelveticaNeue
  font-weight: bold
  letter-spacing: .25px
  cursor: pointer

  @media(max-width: 600px)
    margin: auto

.btnRegresarContainer
    margin-top: 1rem
    position: absolute
    top: 0
    left: 0
    @media(max-width: 600px)
        margin-top: 2rem
        position: absolute
        top: 0
        left: 0

        
.btnRegresar
    background: none
    border: none
    padding: 0
    fot-family: Inter "Helvetica Neue" Sans serif
    font-size: 16px
    line-height: 19.36px
    cursor: pointer

.btnRegresar img
    width: 24px
    height: 24px  

.active
  background-color: $blueInputs
  color: white
  border: none

.error
    margin-top: -1rem
    font-family: HelveticaNeue
    font-weight: 400
    font-size: 14px
    line-height: 22px
    color:#BA4B44

.imageLogo 
    @media(max-width: 600px)
        width: 145px
        height: 68.91px
        left: 5rem
        position: relative