@use '../../utils/sass/_colors.sass' as *

.steps-container 
  position: relative
  display: flex
  justify-content: space-between
  margin-top: 55px
  width: 100%
  align-items: left

.step-item
  position: relative
  display: flex
  width: 12rem
  flex-direction: column
  align-items: center

.step-item:not(:first-child):before 
    position: absolute
    right: 50%
    top: 12%
    height: 8px
    width: 100%
    --tw-translate-y: -50%
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))
    --tw-bg-opacity: 1
    background-color: #EEEDED
    --tw-content: ''
    content: var(--tw-content)

.step
  position: relative
  z-index: 10
  display: flex
  height: 32px
  width: 32px
  align-items: center
  justify-content: center
  border-radius: 9999px
  --tw-bg-opacity: 1
  background-color: #BDBDBD

.stepReload
  position: relative
  z-index: 10
  display: flex
  height: 32px
  width: 32px
  align-items: center
  justify-content: center
  border-radius: 9999px
  --tw-bg-opacity: 1
  background-color: #BDBDBD

.active
  visibility: visible
  background-color: #006FB9

.inactive
  visibility: hidden

.complete .step
  background-color: #006FB9

.pText
  font-family: "HelveticaNeue"
  color: #212121
  width: 117px
  font-size: 16px
  font-weight: 400
  line-height: 22px
  align-self: center
  margin-top: 10px
  text-align: center

.complete:not(:first-child):before
  --tw-bg-opacity: 1
  background-color: rgb(0 111 185 / var(--tw-bg-opacity))

.documentName
  display: flex
  flex-direction: column
  align-items: center
  justify-items: center
  width: 100%

.pTextName
  font-family: "HelveticaNeue"
  color: #212121
  width: 100%
  font-size: 16px !important
  font-weight: 400
  line-height: 22px
  align-self: center
  margin-top: 40px
  text-align: center

input[type="range"]
  -webkit-appearance: none
  height: 8px
  width: 100%
  background: #BDBDBD
  border-radius: 5px
  background-image: linear-gradient(#0066A5, #0066A5)
  background-repeat: no-repeat

input[type="range"]::-webkit-slider-thumb
  -webkit-appearance: none
  height: 28px
  width: 28px
  border-radius: 50%
  background: #0066A5
  transition: background 0.3s ease-in-out

input[type="range"]::-webkit-slider-runnable-track
  -webkit-appearance: none
  box-shadow: none
  border: none
  background: transparent

input[type="range"]::-moz-slider-thumb
  height: 28px
  width: 28px
  border-radius: 50%
  background: #0066A5
  transition: background 0.3s ease-in-out

input[type="range"]::-moz-slider-runnable-track
  box-shadow: none
  border: none
  background: transparent

