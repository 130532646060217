@use '../../../utils/sass/_colors.sass' as *

.whiteSpace
  height: 300px

.important > div > h4
  font-size: 1.5em !important

.contactForm
  font-size: 1rem
  width: 100%
  padding: 1.5em
  background-color: white
  box-shadow: 0px 3px 6px rgba(18, 18, 18, 0.16)
  border-radius: 8px
  overflow: hidden

.title
  font-family: "CircularStd-Bold"
  font-size: 2rem

.subtitle
  font-size: .8125rem
  font-family: "HelveticaNeue-Medium"

.secondaryTitle
  font-family: "CircularStd-Bold"

.inputsSection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em

@media screen and (max-width: 600px)
.inputsSection
  grid-template-columns: 1fr

.radiosSection
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 1.5em
  grid-row-gap: 1.5em

@media screen and (max-width: 600px)
.radiosSection
  display: grid
  grid-template-columns: 1fr

.phoneRadios
  grid-row: 1/2

.emailRadios
  grid-row: 1/2

.heroControls
  height: 6.25rem
  display: flex
  align-items: center
  justify-content: space-between

.btnBack
  color: $main-blue
  border-style: none
  font-weight: bold
  letter-spacing: .25px
  background-color: transparent
  cursor: pointer

@media(max-width: 600px)
  .btnBack
    display: none

.btnNext
  width: 312px
  height: 48px
  background: rgb(224, 224, 224)
  border-radius: 24px
  border-style: solid
  font-weight: bold
  letter-spacing: .25px
  cursor: pointer

  @media(max-width: 600px)
    margin: auto

.active
  background-color: $inputBlue
  color: white
